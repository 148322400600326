@import "./assets/css/include/_variables_default.scss";
.item-card__presentation {
  display: flex;
  flex-direction: column;
  background-color: $bg-grey;
  border-radius: $border-radius-base;
  .item-card__presentation--img {
    border-radius: 10px;
  }

  /* Trombi */
  .item-card__presentation--imgs {
    position: relative;
    display: flex;
    flex-direction: column;
    .item-card__presentation--img-trombi {
      border-radius: 10px;
      transition: opacity .5s ease-in-out;
    }
    .item-card__presentation--img-trombi-group {
      opacity:0;
      top: 0;
      position:absolute;
      &:hover {
        opacity: 1;
      }
    }
  }
  .view__agency-map {
    position: relative;
    max-width: 300px;
    margin: auto;

    .item-card__presentation--cta {
      position: absolute;
      width: 90%;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, 0);
      padding-left: 10px;
    }
  }

  .item-card__presentation--content{
    padding: 10px;
    h3 {
      font-family: $font-family-bold;
      font-size: 2rem;
      line-height: 1;
      margin: 0;
    }
    i {
      color: $secondary-color;
      font-size: 2.5rem;
    }
    a {
      color: $text-color;
      &:hover{
        color: $primary-color;
      }
    }
  }
  .item-card__presentation--content__title {
    color: $secondary-color;
    line-height: 1;
    margin-bottom: 10px;
    strong {
      font-family: $font-family-bold;
    }
  }
}