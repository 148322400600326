@import "./assets/css/include/_variables_default.scss";
.block-avis-clients {
  padding: 25px 0;
  @include media-breakpoint-up(lg){
    padding: 70px 0 20px;
  }
}

.home-content__garage-score {
  display: block;
  overflow: auto;
  padding: 0 30px;
  text-align: center;
  white-space: nowrap;
  max-width: 850px;
  margin: auto;
}