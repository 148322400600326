@import "./assets/css/include/_variables_default.scss";
.block-presentation {
  padding: 40px 0 20px;
  h2 {
    line-height: 1!important;
  }
  @include media-breakpoint-up(lg) {
    padding: 80px 0 40px;
    h2 {
      line-height: 1.4!important;

    }
  }
}