$primary-color   : #FF6D1D;
$secondary-color : #012670;
$tertiary-color  : #001B53;
$fourthly-color  : #FCCB2D;

$title-color : $secondary-color;
$text-color  : #000;
$gray-text   : #707070; // Utile dans le BreadCrumb
$inverse-color: #fff;

// Boutons
//$btn-primary_default : $primary-color;
//$btn-primary_hover   : #FC5802;

// BACKGROUNDS
$bg-body  : #fff;
$bg-grey  : #F6F6F6;
$bg-dark  : $secondary-color;
//$bg-hover : #cbdde6;
//$bg-form  : #cbdde6;

//
$bg-range-slider-bar: $secondary-color;
$disabled-color: #ccc;
$placeholder-color: $secondary-color;

// HAUTEURS FIXES DE BLOCKS HEADER
$height-header-desktop : 96px;
$height-header-mobile  : 75px;
/*$height-sticky-anchors-desktop : 83px;
$height-sticky-anchors-mobile  : 53px;*/

// BORDERS STYLES
//$border-width: 1px;
//$border-style: solid;
//$border-color: $primary-color;

// STEPS
//$step-color-current: #36A59A;

// BOUTONS / LIENS
//$link-color : $text-color;
//$bg-link-seo : #e6e6e6;

// Configuration Bootstrap
$state-success-bg: $primary-color;
$state-danger-bg: #e4022e;
$border-radius-big : 20px;
$border-radius-base: 10px;
$border-radius-small: 5px;
$border-radius-circle: 100px;
$grid-gutter-width: 20px !default;
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
    10: ($spacer * 1),
    20: ($spacer * 2),
    30: ($spacer * 3),
    40: ($spacer * 4),
    50: ($spacer * 5),
    60: ($spacer * 6),
    70: ($spacer * 7),
    80: ($spacer * 8),
    90: ($spacer * 9)
  ),
  $spacers
);

// Override default BT variables:
/*$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1540px
);*/

// Toastify COLORS
$warning-color : #ff5E13;
$success-color : #28a745;
$info-color :    $primary-color;
$error-color:    #dc3545;

//Breadcrumb
$breadcrumb-bg: #CBDDE6;

// FONTS
$font-family-bold     : 'cairobold';
$font-family-semibold : 'cairosemibold';
$font-family-regular  : 'cairoregular';
$font-family-light    : 'cairolight';

$font-size-base       : 1.7rem;
$font-size-mobile     : 1.5rem;

@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/_mixins";