@import "./assets/css/include/_variables_default.scss";
.block-heroZone {
  background-color: $tertiary-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px 25px;
  @include media-breakpoint-up(lg){
    padding: 55px 0 45px;
  }
  h1 {
    font-size: 5rem;
    color: $inverse-color;
    margin: 0;
    span {
      display: block;
      font-family: $font-family-regular;
      font-size: 2.5rem;
      @include media-breakpoint-up(sm) {
        font-size: 3rem;
      }
      @include media-breakpoint-up(md) {
        font-size: 4.2rem;
      }
      @include media-breakpoint-up(lg) {
        font-size: 6rem;
      }
    }
    @include media-breakpoint-up(md) {
      font-size: 7.2rem;
    }
  }
  .baseline {
    font-size: 1.7rem;
    color: $inverse-color;
    @include media-breakpoint-up(md) {
      font-size: 2.4rem;
    }
    a {
      color: $inverse-color;
      &:hover {
        color: $primary-color;
      }
    }
  }
  &.block-heroZone__bg {
    h1 span {
      font-size: 2.5rem!important;
      @include media-breakpoint-up(md) {
        font-size: 4.2rem!important;
      }
    }
  }
  /*&.block-heroZone__bg-Cahors {
    background: linear-gradient(135deg, $secondary-color,50%, transparent), url("../../../images/agencies/img_bg-sd-cahors.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  &.block-heroZone__bg-Toulouse {}*/
}