@import "./assets/css/include/_variables_default.scss";
.algoliaApp {
  display: flex;
  .form-control {
    font-size: 1.6rem;
    font-family: $font-family-bold ;
    padding-left: 30px;
    &::-moz-placeholder {
      color: $placeholder-color;
      font-family: $font-family-bold ;
    }
    &::-webkit-input-placeholder {
      color: $placeholder-color;
      font-family: $font-family-bold ;
    }
    &:-ms-input-placeholder  {
      color: $placeholder-color;
      font-family: $font-family-bold ;
    }
  }
  .react-autosuggest__container {
    flex-grow: 1;
  }
  .btn-submit__algoliaApp {
    background-color: $fourthly-color;
    padding: 10px 20px 6px;
    margin-left: 20px;
    &:hover {
      background-color: $primary-color;
    }
    i {
      font-size: 3.5rem;
      color: $secondary-color;
    }
  }
}

.algoliaApp__hp {
  .algoliaApp__hp--find-more {
    display: flex;
    padding-right: 100px;
    padding-bottom: 5px;
  }
  .algoliaApp__hp--find-more-line {
    width: 100%;
    height: 10px;
    background-color: $fourthly-color;
    border-top-left-radius: $border-radius-base;
    border-bottom-right-radius: $border-radius-base;
    margin: 10px 0 0 0;
  }
  .algoliaApp__hp--find-more-link {
    display: flex;
    flex-wrap: nowrap;
    color: $inverse-color;
    margin-left: 15px;
    a {
      color: $inverse-color;
      white-space: nowrap;
      text-decoration: underline;
      &:hover {
        color: $primary-color
      }
    }

  }
}

//sugestion moteur home
.react-autosuggest__container {
  position: relative;
  > div {
    display: block;
  }
  .react-autosuggest__suggestions-container {
    position: absolute;
    z-index: 3;
    width: 100%;
    left: 0;
    background-color: transparent;
    @include media-breakpoint-up(md) {
      width: 100%;
      left: 0;
      right: 0;
    }
  }
  .react-autosuggest__suggestions-list {
    border-radius: $border-radius-base;
    max-height: 361px;
    overflow: auto;
    background-clip: padding-box;
    background-color: $inverse-color;
    margin-bottom: 0;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.15);
    &::-webkit-scrollbar-thumb {
      background-color: $secondary-color;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      //padding-top: 10px;
    }
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: $inverse-color;
    }
  }
  .react-autosuggest__suggestion {
    margin-bottom: 0;
    cursor: pointer;
    padding: 0 15px;
    text-align: left;
    &:before {
      content: none;
    }
    .dropdown-item {
      line-height: 2rem;
      padding: 12px 0;
      font-family: $font-family-regular;
      &:active {
        background-color: $secondary-color;
      }
    }
  }
}

.react-autosuggest__suggestion:not(:last-child) .dropdown-item {
  border-bottom: 1px solid $secondary-color;
}