@import "./assets/css/include/_variables_default.scss";
.item-card__contact {
  font-size: 1.7rem;
  color: $secondary-color;
  margin-bottom: 20px;
  i {
    font-size: 3rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 2.4rem;
    color: $secondary-color;
    margin-bottom: 20px;
    i {
      font-size: 4rem;
    }
  }
}