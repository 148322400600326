@import "./assets/css/include/_variables_default.scss";
.block-experience {
  position: relative;
  margin-top: 0;
  padding: 25px 0;
  z-index: 1000;
  background-color: $bg-grey;
  @include media-breakpoint-up(xl){
    margin-top: -80px;
    padding: 100px 0 70px;
  }
}