@import "./assets/css/include/_variables_default.scss";
.block-stocks {
  padding: 25px 0;
  @include media-breakpoint-up(lg){
    padding: 70px 0;
  }
  .block-stocks__vo {
    margin-bottom: 20px;
    @include media-breakpoint-up(lg){
      margin-bottom: 0;
    }
    h2 {
      color: $primary-color;
    }
  }
  .block-stocks__vn {
    margin-bottom: 20px;
    @include media-breakpoint-up(lg){
      margin-bottom: 0;
    }
  }
}
.block-stocks__mentions-funding {
  white-space: initial;
  font-family: $font-family-bold;
  font-size: 1.8rem;
  line-height: 1;
  text-align: center;
}