@import "./assets/css/include/_variables_default.scss";
.item-card__testimonial {
  display: flex;
  flex-direction: column;
  background-color: $inverse-color;
  border-radius: $border-radius-big;
  padding: 20px;
  .item-card__testimonial--stars {
    display: flex;
    flex-wrap: wrap;
    font-size: 2.8rem;
    color: $bg-grey;
    .active {
      color: $fourthly-color;
    }
  }
  .item-card__testimonial--icon {
    font-size: 3.8rem;
    color: $secondary-color;
    margin-top: 3px;
  }
  .item-card__testimonial--name {
    font-size: 1.8rem;
    color: $secondary-color;
  }
  .item-card__testimonial--date {
    font-size: 1.4rem;
    font-family: $font-family-light;
    color: $secondary-color;
  }
  .item-card__testimonial--maker-model {
    font-size: 1.8rem;
    font-family: $font-family-bold;
    color: $secondary-color;
    margin-bottom: 0;
  }
}

.item-card__testimonial-response {
  position: relative;
  background-color: $secondary-color;
  font-family: $font-family-regular;
  border-radius: $border-radius-big;
  padding: 15px;
  margin: 20px 0 15px;
  .triangle-code{
    position: absolute;
    display : inline-block;
    height : 0;
    width : 0;
    border-right : 18px solid transparent;
    border-bottom : 18px solid $secondary-color;
    border-left : 18px solid transparent;
    top: -15px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .item-card__testimonial-response--title {
    font-size: 1.8rem;
    color: $fourthly-color;
    margin-bottom: 5px;
    i {
      margin-right: 5px;
    }
  }
  .item-card__testimonial-response--text {
    font-size: 1.5rem;
    color: $inverse-color;
    margin-bottom: 0;
  }
  .btn-more {
    color: $inverse-color;
    &:after {
      background-color: $inverse-color;
      color: $secondary-color;
    }
  }
}