@import "./assets/css/include/_variables_default.scss";
.block-services {
  position: relative;
  z-index: 1001;
  background:
          url('../../images/homepage/img_hp-bckgr-services01.png') no-repeat left center,
          url('../../images/homepage/img_hp-bckgr-services02.png') no-repeat right center;
  margin-top: -80px;
  padding-top: 180px;
  padding-bottom: 250px;
  @include media-breakpoint-down(lg) {
    margin-top: 0;
    background: $inverse-color;
    padding-top: 70px;
    padding-bottom: 70px;
  }
  @include media-breakpoint-down(md){
    padding: 50px 0;
  }
  .row {
    padding: 50px 0 0 0;
    @include media-breakpoint-down(lg) {
      padding: 0;
    }
  }
}