@import "./assets/css/include/_variables_default.scss";
.item-vehicle {
  border-radius: $border-radius-base;
  background-color: $inverse-color;
  border-width: 2px;
  border-style: solid;
  transition :all 0.3s ease;
  figure {
    margin-bottom: 0;
    img {
      border-radius: 10px;
    }
  }
  > a, > a:hover {
    text-decoration: none;
  }
  &:hover, &:focus {
    box-shadow: 2px 6px 6px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    text-decoration: none;
  }
  .item-vehicle__wrapper {
    padding: 9px 12px;
    display: flex;
    flex-direction: column;
    > a, > a:hover {
      text-decoration: none;
    }
  }
}
.item-vehicle__title {
  margin-top: 0;
  margin-bottom: 5px;
  .btn {
    font-size: 2rem;
    padding: 0 2px;
  }
  .btn-parking {
    padding-right: 0;
  }
}
.item-vehicle__maker-model {
  flex-grow: 1;
  font-family: $font-family-regular;
  font-size: 2rem;
  line-height: 2;
  color: $text-color;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  span {
    font-family: $font-family-bold;
  }
}

.item-vehicle__version {
  font-size: 1.5rem;
  line-height: 1.8rem;
  font-family: $font-family-light;
  color: $text-color;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.item-vehicle__infos {
  font-family: $font-family-light;
  font-size: 1.5rem;
  line-height: 1.8rem;
  margin-bottom: 4px;
  color: $text-color;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.item-vehicle__prices {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .item-vehicle__prices--monthly {
    display: flex;
    align-items: center;
    flex-grow: 1;
    align-self: flex-end;
    font-family: $font-family-bold;
    font-size: 1.8rem;
    line-height: 1;
    color: $text-color;
    padding-bottom: 3px;
    > a, > a:hover {
      text-decoration: none;
      color: $text-color;
    }
    .item-vehicle__prices--monthly-value {
      letter-spacing: -.05rem;
      @media screen and (min-width: 1200px) and (max-width: 1340px) {
        font-size: 1.3rem;
      }
      span {
        font-family: $font-family-semibold;
        font-size: 1.2rem;
      }
    }
    .info {
      font-size: 0.9rem;
      line-height: 1.4;
      text-align: center;
      width: 16px;
      height: 16px;
      border: 2px solid $text-color;
      border-radius: 50%;
      margin-left: 3px;
    }
  }
  .item-vehicle__prices--discount {
    text-decoration: line-through;
    font-family: $font-family-bold;
    font-size: 1.2rem;
    line-height: 1;
    color: $text-color;
    min-height: 14px;
    margin-bottom: -4px;
  }
  .item-vehicle__prices--price {
    font-family: $font-family-bold;
    font-size: 2.4rem;
    line-height: 1;
  }
}

// Item OCCASION - vo
.item-vehicle__vo {
  border-color: $primary-color;
  .item-vehicle__maker-model span {
    color: $primary-color;
  }
  .item-vehicle__prices--price {
    color: $primary-color;
  }
  .label-tag {
    color: $primary-color;
  }
}

// Item NEUF - vn
.item-vehicle__vn {
  border-color: $secondary-color;
  .item-vehicle__maker-model span {
    color: $secondary-color;
  }
  .item-vehicle__prices--price {
    color: $secondary-color;
  }
  .label-tag {
    color: $secondary-color;
  }
}